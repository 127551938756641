import {
  Placement, CsgOrderModel, CustomerSuppliedModelItem,
  ReceivedCsgRequestItemModel, CsgReceivedItem, CsgReceiveResponse, CsgRequestItemViewModel, DesignDetail, ColorMatchResult, OrderFile, PendingCsgOrdersWithSameDesignIds
} from './../../models/index';
import { shortDate } from './../../displayHelpers';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import * as moment from 'moment';
import * as uuid from 'uuid';
import * as _ from 'lodash';
import { FileService } from 'src/app/services/file.service';
import { longDateWithSeconds } from '../../displayHelpers';
import {
  CsgPdfForm,
  CsgRequestDetailItem,
  CsgRequestItemModel,
  LocationModel,
  ReleasedCsgRequestItemModel,
  SalesOrderDetailItem,
  SalesOrderListItem,
} from '../../models';
import { AuthService } from '../../services/auth.service';
import { CsgRequestsService } from '../../services/csg-requests.service';
import { LocationLookupService } from '../../services/location-lookup.service';
import { NotificationService } from '../../services/notification.service';
import { SalesOrderService } from '../../services/sales-order.service';
import { ModalService } from 'src/app/services/modal-service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

const MissingThreadTranslation = 'Thread Translation Missing';
const DesignImportFailed = 'Design Import Failed';
const PriceException = 'Price Tolerance Exception';
const BadThreadData = 'Unable To Process Order. Invalid Thread Data';
const DesigRequestPending = 'Design Request Pending';
const DesigRequestPendingPartial = 'Partially Received; Design Request Pending';
const DesigRequestPendingReceived = 'Received; Design Request Pending';
const ThreadUpdatedPleaseReimport = 'Thread Data Updated; Please Reimport';


const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-csgrequest-detail',
  templateUrl: './csgrequest-detail.component.html',
  styleUrls: ['./csgrequest-detail.component.scss', './../../app.component.scss']
})
export class CsgrequestDetailComponent implements OnInit {

  filesToUpload = new Array<File>();

  ////
  model: CsgRequestDetailItem;
  originalModel: CsgRequestDetailItem;
  stripeDesigns = [
    { code: '006', name: '1.5 in. Yellow/Silver/Yellow ( .5 in. Reflective)' },
    { code: '005', name: '1.5in. Orange/Silver/Orange (.5 in. Reflective)' },
    { code: '004', name: '2.5 in. Yellow/Silver/Yellow (1/2 in. Refl.) SB4224' },
    { code: '003', name: '2.5 in. Orange/Silver/Orange (1/2 in. Refl.) SB4123' },
    { code: '013', name: '2.5 in. Yellow/Silver/Yellow (1 in. Silver)' },
    { code: '002', name: '1 in. Silver Reflective SB2799' },
    { code: '012', name: '1 3/8 in. Silver' },
    { code: '001', name: '2 in. Silver Reflective SB4022 ' },
    { code: '008', name: '2 in. FR Yellow/Silver/Yellow (3/4 in. Refl.)SB2500' },
    { code: '009', name: '2 in. FR Orange/Silver/Orange (¾ in. Reflective)' },
    { code: '007', name: '1 in. FR Silver Reflective SB3213' },
    { code: '011', name: '2.5 in. FR Yellow' },
    { code: '010', name: '2 in. FR Silver (Reflective)' },
  ];
  orderSkus = new Array<string>();
  placements = new Array<{
    id: string,
    name: string
  }>();
  longDateWithSeconds = longDateWithSeconds;
  shortDate = shortDate;
  isReceiving: boolean;
  locationModel = new Array<LocationModel>();
  selectedLocation = '';
  comments = '';
  numberOfBoxes = 0;
  salesOrderDetailModel: SalesOrderDetailItem;
  loading = false;
  commentsSaved = false;
  salesOrders = new Array<SalesOrderListItem>();
  salesOrdersIds = '';
  csgPdfFormInfo = new Array<CsgPdfForm>();
  receivingItem: any;
  disableRelease = false;
  designDetails = new Array<DesignDetail>();
  selectedDesignDetails = new Array<DesignDetail>();
  selectedOrderFile: OrderFile;
  csgRequestId: string;
  hideCustomerSupplied = false;
  shouldSendReceivingEmail = false;
  bsConfig: Partial<BsDatepickerConfig>;
  receivePending = false;
  colorMatchReadOnly = false;
  otherRequestsWithSameDesigns: PendingCsgOrdersWithSameDesignIds[];
  otherRequestsLoading = false;
  getUploadedURLs: string = '';
  
  constructor(private csgService: CsgRequestsService,
    private salesOrderService: SalesOrderService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private locationService: LocationLookupService,
    private modalService: ModalService,
    private fileService: FileService) {
  }


  ngOnInit() {
    this.loading = true;
    this.otherRequestsLoading = true;
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false });
    const { csgRequestId } = this.route.snapshot.params;
    this.csgRequestId = csgRequestId;
    this.locationService.getLocations(true).subscribe(results => {
      this.locationModel = results;
    });
    this.csgService.getCsgRequest(csgRequestId, true).subscribe(r => {
      this.loadCsgRequest(r);
      this.getDesignDetails(r);
    });
    this.csgService.getRequestsWithSameDesignIds(csgRequestId).subscribe(otherRequests => {
      this.otherRequestsWithSameDesigns = otherRequests.filter(c => c.id !== csgRequestId);
      this.otherRequestsLoading = false;
    });
  }

  getDesignDetails(csgOrder: CsgRequestDetailItem): void {
    if (this.isLegacyCsg()) {
      csgOrder.rawOrderModel.garmentGroups.forEach(c =>
        c.garmentDesigns.forEach(d => {
          if (d && d.product && d.product.designDetail) {
            d.product.designDetail.forEach(e => {
              e.designId = d.product.designId;
              if (this.designDetails.findIndex((f: DesignDetail) => f.designId === e.designId &&
                f.order === e.order &&
                f.sourceCode === e.sourceCode) === -1) {
                e.originalSourceCode = e.sourceCode;
                e.garmentStyle = c.styleCode;
                e.garmentColor = c.color;
                this.designDetails.push(e);
              }
            });
          }
        }));
    } else {
      csgOrder.rawOrderModel.garmentGroups.forEach(c =>
        c.operations.forEach(d => {
          if (d.design && d.design.designDetail) {
            d.design.designDetail.forEach(e => {
              e.designId = d.design.designId;
              if (this.designDetails.findIndex((f: DesignDetail) => f.designId === e.designId &&
                f.order === e.order &&
                f.sourceCode === e.sourceCode) === -1) {
                e.originalSourceCode = e.sourceCode;
                e.garmentStyle = c.styleCode;
                e.garmentColor = c.color;
                this.designDetails.push(e);
              }
            });
          }
        }));
    }
  }

  loadCsgRequest(r: CsgRequestDetailItem) {
    this.model = r;
    this.model.cancelByDate = this.getCancelDate();
    this.csgService.getCsgRequestFiles(r.id).subscribe(f => {
      this.model.files = f;
    });
    const grouped = _.groupBy(this.model.csgRequestItems, i => i.lineNumber);
    const model = Object.keys(grouped).map(g => ({
      ...grouped[g][0],
      placements: grouped[g].map(x => ({
        catalogSku: x.catalogSku,
        garmentPlacementName: x.garmentPlacementName,
        garmentPlacementId: x.garmentPlacementId,
        id: x.id,
        isNameDropper: x.isNameDropper || false,
        nameLine1: x.nameLine1,
        nameLine2: x.nameLine2 || undefined,
        nameLine3: x.nameLine3 || undefined,
        nameComment: x.nameComment || undefined,
      }))
    }));
    this.model.csgRequestItemsModel = model;
    this.model.customerSuppliedItems = this.getCustomerSuppliedItems(this.model.rawOrderModel);
    this.model.receivedItems = model.filter(i => i.quantityReceived > 0).map(i =>
      <ReceivedCsgRequestItemModel><unknown>{
        ...i,
        dateReleased: this.model.releasedItems.find(ri => ri.parentItemId === i.id).dateReleased,
        isReleased: this.model.releasedItems.findIndex(ri => ri.parentItemId === i.id) > -1,
        releasedBy: this.model.releasedItems.find(ri => ri.parentItemId === i.id).releasedBy      
      });

    this.orderSkus = Array.from(new Set(r.csgRequestItems.map(i => i.catalogSku).values()));
    const placements = Array.from(new Set(r.csgRequestItems.map(i => i.garmentPlacementName).values()));
    this.placements = placements.map(p => (
      {
        id: r.csgRequestItems.find(i => i.garmentPlacementName === p).garmentPlacementId,
        name: p
      }));

    if (r.warehouseCode !== '' && r.warehouseCode != null) {
      const loc = this.locationModel.find(c => (c.locationCode === r.warehouseCode) ||
        (r.warehouseCode === `${c.locationCode2Dig} ${c.locationCode}`));
      if (loc != null && loc !== undefined) {
        this.selectedLocation = loc.locationCode2Dig + ' ' + loc.locationCode;
      }
    }
    this.comments = r.comments;
    this.numberOfBoxes = r.numberOfBoxes;

    this.salesOrderService.getSalesOrderFromCsgId(this.model.id).subscribe(detail => {
      if (detail) {
        this.salesOrderDetailModel = detail;
      }
      this.loading = false;
    });
    this.salesOrderService.getSalesOrdersFromCsgId(this.model.id).subscribe(orders => {
      this.salesOrders = orders;
      const ids = this.salesOrders.map(item => item.id);
      this.salesOrdersIds = ids.join(',');
    });
    this.salesOrderService.getCsgPDF(this.model.id).subscribe(pdf => {
      this.csgPdfFormInfo = pdf;
    });
  }
  saveBoxesAdd() {
    this.numberOfBoxes += 1;
  }
  saveBoxesMinus() {
    if (this.numberOfBoxes > 0) {
      this.numberOfBoxes = this.numberOfBoxes - 1;
    } else {
      return;
    }
  }
  saveBoxes() {
    this.csgService.updateCsgRequestBoxes(this.model.id, this.numberOfBoxes, true).subscribe(response => {
      this.notificationService.showPopup('Number of boxes updated!', 'warning', 'Updated');
    }, err => {
      this.notificationService.showPopup('Error updating Csg request boxes', 'danger', 'Error');
    });
  }
  saveComments() {
    this.csgService.updateCsgRequestComments(this.model.id, this.comments).subscribe(response => {
      this.commentsSaved = true;
      this.notificationService.showPopup('CSG request comments updated!', 'warning', 'Updated');
    }, err => {
      this.notificationService.showPopup('Error updating Csg request comments', 'danger', 'Error');
    });
  }
  setStripeName(item: CsgRequestItemModel) {
    item.stripeDesignName = (this.stripeDesigns.find(d => d.code === item.stripeDesignCode) || { name: '' }).name;
  }

  allReleased() {
    if (this.isReceiving) {
      return false;
    }
    return this.model.csgRequestItems.filter(i => i.quantity <= i.quantityReceived).length ===
      this.model.csgRequestItems.length;
  }

  release() {

    //Call this method once we are done with all Uploads and want to save
    this.fileService.uploadCSGFiles(this.filesToUpload, this.model.receivingId).subscribe(
      (response) => {
        for (var i = 0; i < response.length; i++)
          this.getUploadedURLs += response[i].uploadedUrl + ';';

        this.filesToUpload = new Array<File>();
        if (this.disableRelease) {
          return;
        }

        //show message when qty received is greater than quantity for a garment group
        const receiveValidation = this.model.csgRequestItemsModel.filter(c => (c.quantity - this.model.receivedItems.filter(d => d.groupingId == c.groupingId).reduce((sum, current) => sum + current.quantityReceived, 0)) < c.quantityReceived);
        if (receiveValidation && receiveValidation.length > 0) {
          const garmentGroups = receiveValidation.map(c => c.groupingId);
          const result = confirm(`The Qty Received you entered for Garment Group(s) ${garmentGroups.join(',')} is greater than the Quantity listed on the order. Do you wish to continue?`);
          if (result == false) {
            return;
          }
        }

        if (!this.model.csgRequestItemsModel.some(i => i.quantityReceived > 0)) {
          return; // dont release 0 garments, thats just silly
        }

        this.isReceiving = false;
        this.disableRelease = true;
        this.receivePending = true;
        if (!this.model.releasedItems) {
          this.model.releasedItems = [];
        }

        this.model.csgRequestItemsModel.forEach(i => {
          if (i.quantityReceived === undefined) {
            i.isInvalid = true;
          } else {
            i.isInvalid = false;
          }
        });
        if (this.model.csgRequestItemsModel.some(i => i.isInvalid)) {
          this.disableRelease = false;
          this.receivePending = false;
          return;
        }


        const releaseThese = new Array<ReleasedCsgRequestItemModel>();
        this.model.csgRequestItemsModel.forEach(x => {
          x.placements.forEach(item => {
            console.log(item);
            console.log(x);
            const releasedItem = {
              ...x, ...item, dateReleased: '',
              parentItemId: '', noDecoration: false, wearer: '', receivingLocation: '', releaseSequence: 0,
              groupingId: x.groupingId, vendorName: '', vendorPO: '', IdTapeNum: '', outsideVendorName: ''
            } as ReleasedCsgRequestItemModel;
            releasedItem.dateReleased = moment().format();
            releasedItem.parentItemId = { ...releasedItem }.id;
            releasedItem.id = uuid.v4();
            releaseThese.push(releasedItem);
            this.model.releasedItems.unshift({ ...releasedItem });
          });
        });
        for (const heldItem of this.model.receivedItems.filter(c => !c.isReleased)) {
          const isReleasingNow = releaseThese.find(i => i.parentItemId === heldItem.id);
          if (isReleasingNow) {
            isReleasingNow.quantityReceived += heldItem.quantityReceived;
            if (isReleasingNow.quantityReceived > isReleasingNow.quantity) {
              isReleasingNow.quantityReceived = isReleasingNow.quantity;
            }
          }
        }



        this.csgService.releaseCsgRequestWithUploads(this.model.id, releaseThese, this.selectedLocation, this.getUploadedURLs)
          .subscribe(response => {
            this.notificationService.showPopup(response.message, 'success', 'Notification');
            // this.disableRelease = false;
            this.csgService.getCsgRequest(this.model.id, true).subscribe(r => {
              this.loadCsgRequest(r);
              this.getDesignDetails(r);
              this.receivePending = false;
            });
          }, err => {
            this.notificationService.showPopup('Error releasing CSG', 'danger', 'Release not successful');
            this.disableRelease = false;
            this.receivePending = false;
          });
        //this.notificationService.showPopup('Successfully Uploaded Documents!', 'success', 'Success!');
      }, err => {
        this.notificationService.showPopup('Error Uploading Documents', 'danger', 'Error');
      }
    );
  }

  async receive() {
    if (this.hasPricingException()) {
      return;
    }
    this.isReceiving = true;
    this.originalModel = JSON.parse(JSON.stringify(this.model));
    this.model.csgRequestItems.forEach(i => i.quantityReceived = undefined);
  }

  cancelReceive() {
    this.isReceiving = false;
    this.model = this.originalModel;
    this.originalModel = null;
    this.filesToUpload = new Array<File>();
  }

  addGarment() {
    this.model.csgRequestItemsModel.push(<CsgRequestItemViewModel>{
      isInvalid: false,
      id: uuid.v4(),
      received: moment().format(),
      createDate: moment().format(),
      addEdit: true,
      status: '',
      quantity: 0,
      quantityReceived: 0,
      quantityDamaged: 0,
      size: '',
      color: '',
      brand: '',
      description: '',
      lineNumber: this.getNextLineNumber(),
      placements: [
        {
          garmentPlacementName: '',
          garmentPlacementId: '',
          id: '',
          isNameDropper: false,
          nameLine1: '',
          nameLine2: '',
          nameLine3: '',
          nameComment: '',
        }
      ],
      isInsulatedGarment: false,
      stripeDesignName: '',
      stripeDesignCode: '',
      numberBoxes: 0,
      isReceived: false,
      isAddedItem: true
    } as CsgRequestItemViewModel);
  }

  getNextLineNumber() {
    const highestNumber = Math.max.apply(Math, this.model.csgRequestItemsModel.map(c => c.lineNumber));
    return highestNumber + 1;
  }

  cancelRequest() {
    this.csgService.cancelCsgRequest(this.model.id).subscribe(response => {
      this.notificationService.showPopup('CSG request cancelled!', 'warning', 'Cancelled');
    }, err => {
      this.notificationService.showPopup('Error cancelling CSG request', 'danger', 'Error');
    });
    this.router.navigate(['/csgrequests']);
  }
  updateLocation() {
    this.csgService.updateCsgRequestLocation(this.model.id, this.selectedLocation).subscribe(response => {
      this.notificationService.showPopup('CSG request location updated!', 'warning', 'Updated');
    }, err => {
      this.notificationService.showPopup('Error updating Csg request location', 'danger', 'Error');
    });
  }
  getDateReleased(item: ReleasedCsgRequestItemModel) {
    return moment(item.dateReleased).fromNow();
  }

  viewCsgLabel(): void {
    this.csgService.lastModel = this.model;
    this.router.navigate(['csgrequests', this.csgRequestId, 'label']);
  }

  downloadDetailedLabelPrevious() {
    const detailedLabelButton = document.getElementById('detailedLabelButton');
    if (detailedLabelButton.classList.contains('disabled')) {
      return;
    } else {
      const doc = new jsPDF();
      const yPosition = 10;
      doc.setFont('arial', 'bold');
      doc.text(20, 20, 'CSG ORDER FORM');
      doc.line(20, 22, 72, 22, 'fill');
      doc.setFont('arial', 'normal');
      if (this.model.receivingId) {
        doc.text(20, 30, 'Receiving ID : ' + this.model.receivingId);
      } else {
        doc.text(20, 30, 'Receiving ID : N/A');
      }
      if (this.model.csgNumber) {
        doc.text(20, 40, 'CSG # : ' + this.model.csgNumber);
      } else {
        doc.text(20, 40, 'CSG # : N/A');
      }
      if (this.model.customerName) {
        doc.text(20, 50, 'Customer Name : ' + this.model.customerName);
      } else {
        doc.text(20, 50, 'Customer Name : N/A');
      }
      if (this.model.customerNumber) {
        doc.text(20, 60, 'Customer # : ' + this.model.customerNumber);
      } else {
        doc.text(20, 60, 'Customer # : N/A');
      }
      if (this.model.clientReferenceNumber) {
        doc.text(20, 70, 'Client Reference # : ' + this.model.clientReferenceNumber);
      } else {
        doc.text(20, 70, 'Client Reference # : N/A');
      }
      if (this.numberOfBoxes) {
        doc.text(20, 80, 'Total Number of Boxes : ' + this.numberOfBoxes);
      } else {
        doc.text(20, 80, 'Total Number of Boxes : 0');
      }
      if (this.salesOrderDetailModel == null) {
        doc.text(20, 90, 'Sales Order # : N/A');
      } else {
        if (this.salesOrdersIds) {
          doc.text(20, 90, 'Sales Order # : ' + this.salesOrdersIds);
        } else {
          doc.text(20, 90, 'Sales Order # : ' + this.salesOrderDetailModel.id);
        }
      }
      // table
      const columns = ['Grouping Id', 'PON', 'Brand', 'Color', 'Description', 'Size', 'Quantity', 'Received Date'];
      const rows = [];
      let totalItemsReceived = 0;
      if (this.model.releasedItems.length > 0) {
        console.log(this.model, 'RELEASEDITEMS', this.salesOrderDetailModel, 'SALESORDERDETAILMODEL', this.salesOrders, 'SALESORDERS');
        for (let i = 0; i < this.model.releasedItems.length; i++) {
          for (let j = 0; j < this.model.csgRequestItems.length; j++) {
            const r = [];
            const releasedItem = this.model.releasedItems[i];
            const item = this.model.csgRequestItems[j];
            if (this.salesOrderDetailModel != null) {
              const lineDetail = this.salesOrderDetailModel.lines[j];
              r.push(releasedItem.lineNumber, lineDetail.pon, releasedItem.brand,
                releasedItem.color, releasedItem.description, releasedItem.size,
                releasedItem.quantityReceived, longDateWithSeconds(releasedItem.dateReleased));
              rows.push(r);
              console.log('ROWS1', rows);
            } else if (this.model.releasedItems.length > 0) {
              r.push(releasedItem.lineNumber, 'N/A', releasedItem.brand, releasedItem.color, releasedItem.description,
                releasedItem.size, releasedItem.quantityReceived, longDateWithSeconds(releasedItem.dateReleased));
              rows.push(r);
              console.log('ROWS2', rows);
            } else {
              r.push(item.lineNumber, 'N/A', item.brand, item.color, item.description, item.size,
                item.quantityReceived, longDateWithSeconds(item.received, false));
              rows.push(r);
              console.log('ROWS3', rows);
            }
            totalItemsReceived += releasedItem.quantityReceived;
          }
        }
      } else {
        console.log('GREATER THAN ZERO?');
        for (let i = 0; i < this.model.csgRequestItems.length; i++) {
          const r = [];
          const item = this.model.csgRequestItems[i];
          if (this.salesOrderDetailModel != null) {
            const lineDetail = this.salesOrderDetailModel.lines[i];
            r.push(item.lineNumber, lineDetail.pon, item.brand, item.color, item.description, item.size,
              item.quantityReceived, longDateWithSeconds(item.received, false));
            rows.push(r);
            console.log('ROWs4', rows);
          } else {
            r.push(item.lineNumber, 'N/A', item.brand, item.color, item.description, item.size,
              item.quantityReceived, longDateWithSeconds(item.received, false));
            rows.push(r);
            console.log('ROWS5', rows);
          }
          totalItemsReceived += item.quantityReceived;
        }
      }
      console.log('ROWSFINAL', rows);
      doc.text(20, 100, 'Total Qty Received : ' + totalItemsReceived);
      doc.autoTable(columns, rows, { startY: 110 });
      if (this.model.receivingId) {
        doc.save('CSG Order - ' + this.model.receivingId + '.pdf');
      } else {
        doc.save('CSG Order - ' + this.model.csgNumber + '.pdf');
      }
    }
  }

  downloadDetailedLabel() {
    const detailedLabelButton = document.getElementById('detailedLabelButton');
    if (detailedLabelButton.classList.contains('disabled')) {
      return;
    } else {
      const doc = new jsPDF();
      const yPosition = 10;
      doc.setFont('arial', 'bold');
      doc.text(20, 20, 'CSG ORDER FORM');
      doc.line(20, 22, 72, 22, 'fill');
      doc.setFont('arial', 'normal');
      if (this.model.receivingId) {
        doc.text(20, 30, 'Receiving ID : ' + this.model.receivingId);
      } else {
        doc.text(20, 30, 'Receiving ID : N/A');
      }
      if (this.model.csgNumber) {
        doc.text(20, 40, 'CSG # : ' + this.model.csgNumber);
      } else {
        doc.text(20, 40, 'CSG # : N/A');
      }
      if (this.model.customerName) {
        doc.text(20, 50, 'Customer Name : ' + this.model.customerName);
      } else {
        doc.text(20, 50, 'Customer Name : N/A');
      }
      if (this.model.customerNumber) {
        doc.text(20, 60, 'Customer # : ' + this.model.customerNumber);
      } else {
        doc.text(20, 60, 'Customer # : N/A');
      }
      if (this.model.clientReferenceNumber) {
        doc.text(20, 70, 'Client Reference # : ' + this.model.clientReferenceNumber);
      } else {
        doc.text(20, 70, 'Client Reference # : N/A');
      }
      if (this.numberOfBoxes) {
        doc.text(20, 80, 'Total Number of Boxes : ' + this.numberOfBoxes);
      } else {
        doc.text(20, 80, 'Total Number of Boxes : 0');
      }
      if (this.salesOrderDetailModel == null) {
        doc.text(20, 90, 'Sales Order # : N/A');
      } else {
        if (this.salesOrdersIds) {
          doc.text(20, 90, 'Sales Order # : ' + this.salesOrdersIds);
        } else {
          doc.text(20, 90, 'Sales Order # : ' + this.salesOrderDetailModel.id);
        }
      }
      // table
      const columns = ['Grouping Id', 'PON', 'Brand', 'Color', 'Description', 'Size', 'Quantity', 'Received Date'];
      const rows = [];
      let totalItemsReceived = 0;
      if (this.model.releasedItems.length > 0) {
        for (let i = 0; i < this.csgPdfFormInfo.length; i++) {
          const item = this.csgPdfFormInfo[i];
          const r = [];
          r.push(item.groupingId, item.pon, item.brand, item.color, item.description, item.size, item.quantity, item.receivedDate);
          rows.push(r);
          totalItemsReceived += parseFloat(item.quantity);
        }
      }
      doc.text(20, 100, 'Total Qty Received : ' + totalItemsReceived);
      doc.autoTable(columns, rows, { startY: 110 });
      if (this.model.receivingId) {
        doc.save('CSG Order - ' + this.model.receivingId + '.pdf');
      } else {
        doc.save('CSG Order - ' + this.model.csgNumber + '.pdf');
      }
    }
  }

  downloadPDF2() {

    const doc = new jsPDF('p', 'mm', [350, 500]);
    const yPosition = 10;
    doc.setFont('arial', 'bold');
    doc.setFontSize(32);
    doc.text(20, 20, 'CSG ORDER LABEL');
    doc.line(20, 22, 74, 22, 'fill');
    doc.setFont('arial', 'normal');
    if (this.model.internalNotes == null || this.model.internalNotes.length < 5) {
      doc.text(20, 30, 'Receiving ID : N/A');
    } else {
      doc.text(20, 30, 'Receiving ID : ' + this.model.internalNotes.slice(0, 5).toString());
    }
    doc.text(20, 40, 'CSG # : ' + this.model.csgNumber);
    doc.text(20, 50, 'Customer Name : ' + this.model.customerName);
    doc.text(20, 60, 'Customer # : ' + this.model.customerNumber);
    doc.text(20, 70, 'Client Reference # : ' + this.model.clientReferenceNumber);
    doc.text(20, 80, 'Total Number of Boxes : ' + this.model.numberOfBoxes);
    if (this.salesOrderDetailModel == null) {
      doc.text(20, 90, 'Sales Order # : N/A');
    } else {
      doc.text(20, 90, 'Sales Order # : ' + this.salesOrderDetailModel.id);
    }
    let totalItemsReceived = 0;
    for (let i = 0; i < this.model.csgRequestItems.length; i++) {
      const item = this.model.csgRequestItems[i];
      totalItemsReceived += item.quantityReceived;
    }
    doc.text(20, 100, 'Total Qty Received : ' + totalItemsReceived);
    if (this.model.internalNotes == null || this.model.internalNotes.length < 5) {
      doc.save('CSG Order - ' + this.model.csgNumber + '.pdf');
    } else {
      doc.save('CSG Order - ' + this.model.internalNotes.slice(0, 5).toString() + '.pdf');
    }
  }
  downloadPDF3() {
    // LANDSCAPE
    const doc = new jsPDF('l', 'mm', [152.4, 101.6]);
    // const doc = new jsPDF('p','in',[6,4]);
    doc.setFont('arial', 'normal');
    doc.setFontSize(7);
    doc.text(4, 5, 'CSG ORDER LABEL');
    doc.line(4, 6, 23, 6, 'fill');
    if (this.model.internalNotes == null || this.model.internalNotes.length < 5) {
      doc.text(4, 10, 'Receiving ID : N/A');
    } else {
      doc.text(4, 10, 'Receiving ID : ' + this.model.internalNotes.slice(0, 5).toString());
    }
    doc.text(4, 13, 'CSG # : ' + this.model.csgNumber);
    doc.text(4, 16, 'Customer Name : ');
    doc.setFontSize(4);
    doc.text(4, 18, this.model.customerName);
    doc.setFontSize(7);
    doc.text(4, 21, 'Customer # : ' + this.model.customerNumber);
    doc.text(4, 24, 'Client Reference # : ' + this.model.clientReferenceNumber);
    doc.text(4, 27, 'Total Number of Boxes : ' + this.model.numberOfBoxes);
    if (this.salesOrderDetailModel == null) {
      doc.text(4, 30, 'Sales Order # : N/A');
    } else {
      if (this.salesOrdersIds) {
        doc.text(4, 30, 'Sales Order # : ');
        doc.setFontSize(4);
        doc.text(4, 32, this.salesOrdersIds);
        doc.setFontSize(7);
      } else {
        doc.text(4, 30, 'Sales Order # : ' + this.salesOrderDetailModel.id);
      }
    }
    let totalItemsReceived = 0;
    for (let i = 0; i < this.model.csgRequestItems.length; i++) {
      const item = this.model.csgRequestItems[i];
      totalItemsReceived += item.quantityReceived;
    }
    doc.text(4, 33, 'Total Qty Received : ' + totalItemsReceived);
    if (this.model.internalNotes == null || this.model.internalNotes.length < 5) {
      doc.save('CSG Order - ' + this.model.csgNumber + '.pdf');
    } else {
      doc.save('CSG Order - ' + this.model.internalNotes.slice(0, 5).toString() + '.pdf');
    }
  }

  downloadPDF() {
    const shortLabelButton = document.getElementById('shortLabelButton');
    if (shortLabelButton.classList.contains('disabled')) {
      return;
    }
    // portrait
    const doc = new jsPDF('p', 'mm', [101.6, 152.4]);
    // const doc = new jsPDF('p','in',[6,4]);
    doc.setFont('arial', 'normal');
    doc.setFontSize(7);
    doc.text(4, 5, 'CSG ORDER LABEL');
    doc.line(4, 6, 26, 6, 'fill');
    if (this.model.receivingId) {
      doc.text(4, 10, 'Receiving ID : ' + this.model.receivingId);
    } else {
      doc.text(4, 10, 'Receiving ID : N/A');
    }

    if (this.model.csgNumber) {
      doc.text(4, 15, 'CSG # : ' + this.model.csgNumber);
    } else {
      doc.text(4, 15, 'CSG # : N/A');
    }
    doc.text(4, 20, 'Customer Name : ');
    doc.setFontSize(5);
    if (this.model.customerName) {
      doc.text(4, 22, this.model.customerName);
    } else {
      doc.text(4, 22, 'N/A');
    }
    doc.setFontSize(7);
    if (this.model.customerNumber) {
      doc.text(4, 25, 'Customer # : ' + this.model.customerNumber);
    } else {
      doc.text(4, 25, 'Customer # : N/A');
    }
    doc.text(4, 30, 'Client Reference # : ');
    if (this.model.clientReferenceNumber) {
      doc.text(4, 32, this.model.clientReferenceNumber);
    } else {
      doc.text(4, 32, 'N/A');
    }
    if (this.numberOfBoxes) {
      doc.text(4, 35, 'Total Number of Boxes : ' + this.numberOfBoxes);
    } else {
      doc.text(4, 35, 'Total Number of Boxes : 0');
    }
    if (this.salesOrderDetailModel == null) {
      doc.text(4, 40, 'Sales Order # : N/A');
    } else {
      doc.text(4, 40, 'Sales Order # : ' + this.salesOrderDetailModel.id);
    }
    let totalItemsReceived = 0;
    if (this.model.csgRequestItems) {
      for (let i = 0; i < this.model.csgRequestItems.length; i++) {
        const item = this.model.csgRequestItems[i];
        totalItemsReceived += item.quantityReceived;
      }
    }
    doc.text(4, 45, 'Total Qty Received : ' + totalItemsReceived);
    if (this.model.receivingId) {
      doc.save('CSG Order - ' + this.model.receivingId + '.pdf');
    } else {
      doc.save('CSG Order - ' + this.model.csgNumber + '.pdf');
    }
  }

  canCancelSalesOrder() {
    return (this.authService.canEditSalesOrder() && this.model.status.toLowerCase() !== 'cancelled');
  }

  hasIssues() {
    return this.model.status === MissingThreadTranslation ||
      this.model.status === DesignImportFailed;
  }

  hasPricingException() {
    return this.model.status === PriceException;
  }

  hasBadThreadData() {
    return this.model.status === BadThreadData;
  }

  hasDesignImportError() {
    return this.model.status === DesignImportFailed;
  }

  hasPendingDesignRequest() {
    return this.model.status === DesigRequestPending ||
      this.model.status === DesigRequestPendingPartial ||
      this.model.status === DesigRequestPendingReceived;
  }

  HasUnreleasableException() {
    return this.hasPricingException() ||
      this.hasBadThreadData() ||
      this.hasDesignImportError() ||
      this.hasThreadUpdatedPleaseReimport();
  }

  hasThreadUpdatedPleaseReimport() {
    return this.model.status === ThreadUpdatedPleaseReimport;
  }

  reenterOrder() {
    const id = this.notificationService.showPopup('Please wait', 'warning', 'Retrying CSG order', false);
    this.csgService.reenterCsgRequest(this.model.id, this.isLegacyCsg()).subscribe(result => {
      this.notificationService.hidePopup(id);
      this.notificationService.showPopup('Reentered CSG request', 'success', 'Success', true);
      this.loadCsgRequest(result.model);
    });
  }

  reprocessUpdatedDesigns() {
    const id = this.notificationService.showPopup('Please wait', 'warning', 'Reprocessing CSG order', false);
    this.csgService.reprocessUpdatedDesigns(this.model.id, this.isLegacyCsg()).subscribe(result => {
      this.notificationService.hidePopup(id);
      this.notificationService.showPopup('Reprocessed CSG request', 'success', 'Success', true);
      console.log(result.model);
      this.loadCsgRequest(result.model);
    });
  }

  isLegacyCsg(): boolean {
    // if the order model has any "operations", then it's the new model
    if (!!this.model) {
      return !this.model.rawOrderModel ||
        this.model.rawOrderModel.garmentGroups.map(g => g.operations).findIndex(o => o && o.length > 0) === -1;
    } else {
      return false;
    }
  }

  getCustomerSuppliedItems(model: CsgOrderModel): CustomerSuppliedModelItem[] {
    return model.garmentGroups.map(g => g.operations.filter(o =>
      o.operationType?.toLowerCase().indexOf('customer') > -1 ||
      o.bag?.toLowerCase().indexOf('customer') > -1 ||
      o.category?.toLowerCase().indexOf('customer') > -1).map(o => (
        {
          qtyExpected: g.quantity,
          quantityReceived: o.quantityReceived,
          description: o.itemDescription,
          receivingLocation: o.receivingLocation,
          garmentGroup: g.groupingId.toString(),
          item: o.bag || o.category || o.operationType,
          operationType: o.operationType,
          placementId: o.placement?.id?.toString()
        }
      ))).reduce((full, curr) => full.concat(curr), []);
  }

  receiveCustomerSuppliedItem(event: MouseEvent) {

    if (!(event.target as HTMLFormElement).checkValidity()) {
      return false;
    }
    const item = this.receivingItem;
    if ((event as any)?.submitter?.id === 'receive_cust_all') {
      item.quantityReceived = item.qtyExpected;
    }

    const modelItem = <CsgReceivedItem>{
      garmentGroupId: item.garmentGroup,
      itemDescription: item.description,
      location: item.receivingLocation,
      operationType: item.operationType,
      placementId: item.placementId,
      quantity: item.quantityReceived
    };
    this.csgService.receiveOnly({ csgRequestId: this.model.id, items: [modelItem] }, this.shouldSendReceivingEmail).subscribe(response => {
      item.savedStatus = 'Updated received quantity.';
      item.saveSuccess = true;
      setTimeout(() => {
        item.savedStatus = undefined;
        item.saveSuccess = false;
        item.saveError = false;
      }, 3000);
    }, (err: CsgReceiveResponse) => {
      console.log(err);
      item.savedStatus = err?.messages[0] || 'Error receiving item';
      item.saveSuccess = false;
    });
    return true;
  }

  renderedDesignUrl(placement: Placement, groupingId: number): string {
    const gg = this.model.rawOrderModel?.garmentGroups.find(g => g.groupingId.toString() === groupingId.toString()) || undefined;
    if (!gg) {
      return undefined;
    }

    if (this.isLegacyCsg()) {
      const design = gg.garmentDesigns.find(gd => gd.product?.sku === placement.catalogSku);
      if (!design) {
        return undefined;
      }
      return design.product?.designFile?.renderedDesignImageUrl;

    } else {
      let design;

      if (placement.catalogSku === 'APPLY' || placement.catalogSku === 'SEWAPPLY') {
        design = gg.operations.find(o => !!o.placement && placement.garmentPlacementName.indexOf(o.placement.name) > -1);
      } else {
        design = gg.operations.find(o => o.design?.sku === placement.catalogSku);
      }

      if (!design) {
        return undefined;
      }

      const imageFile = gg.garmentFiles
        .find(gf => gf?.fileType === 'StitchFileImage' &&
          design?.fileIds?.findIndex(fid => fid === gf?.id) > -1);

      if (imageFile) {
        return imageFile.url;
      } else {
        return undefined;
      }
    }
  }

  anySelectedForRelease(): boolean {
    return this.model.receivedItems?.findIndex(i => i.doRelease) > -1;
  }

  receiveAndHold() {
    this.isReceiving = false;
    if (!this.model.receivedItems) {
      this.model.receivedItems = [];
    }

    //show message when qty received is greater than quantity for a garment group
    const receiveValidation = this.model.csgRequestItemsModel.filter(c => (c.quantity - this.model.receivedItems.filter(d => d.groupingId == c.groupingId).reduce((sum, current) => sum + current.quantityReceived, 0)) < c.quantityReceived);
    if (receiveValidation && receiveValidation.length > 0) {
      const garmentGroups = receiveValidation.map(c => c.groupingId);
      const result = confirm(`The Qty Received you entered for Garment Group(s) ${garmentGroups.join(',')} is greater than the Quantity listed on the order. Do you wish to continue?`);
      if (result == false) {
        return;
      }
    }

    this.model.csgRequestItemsModel.forEach(i => {
      if (i.quantityReceived === undefined) {
        i.isInvalid = true;
      } else {
        i.isInvalid = false;
      }
    });
    if (this.model.csgRequestItemsModel.some(i => i.isInvalid)) {
      return;
    }

    if (!this.model.receivingLocation || this.model.receivingLocation.trim() === '') {
      this.isReceiving = true;
      this.notificationService.showPopup('Please enter the receiving location', 'danger', 'Validation');
      return;
    }

    const receiveItems = [];

    const receiveThese = new Array<ReceivedCsgRequestItemModel>();
    this.model.csgRequestItemsModel.forEach(x => {
      x.placements.forEach(item => {
        const receivedItem = {
          ...x, ...item, dateReleased: '',
          parentItemId: '', noDecoration: false, wearer: '',
          receivingLocation: this.model.receivingLocation,
          dateReceived: moment().format(),
          isReleased: false, doRelease: false, groupingId: x.groupingId, IdTapeNum: x.IdTapeNum
        } as ReceivedCsgRequestItemModel;
        receiveThese.push(receivedItem);
        this.model.receivedItems.unshift({ ...receivedItem });
        const modelItem = <CsgReceivedItem>{
          garmentGroupId: receivedItem.lineNumber,
          itemDescription: undefined,
          location: receivedItem.receivingLocation,
          operationType: undefined,
          placementId: undefined,
          quantity: receivedItem.quantityReceived
        };
        receiveItems.push(modelItem);
      });
    });
    this.csgService.receiveOnly({ csgRequestId: this.model.id, items: receiveItems }, this.shouldSendReceivingEmail).subscribe(response => {
      this.notificationService.showPopup('Items received successfully!', 'success', 'Receive OK');
    }, (err: CsgReceiveResponse) => {
      console.log(err);
      this.notificationService.showPopup(err?.messages[0] || 'Error receiving item', 'danger', 'Receive not successful');
    });
  }

  releaseSelectedItems() {
    this.isReceiving = false;
    if (!this.model.releasedItems) {
      this.model.releasedItems = [];
    }

    const selectedItems = this.model.receivedItems.filter(r => r.doRelease);

    const releaseThese = new Array<ReleasedCsgRequestItemModel>();
    selectedItems.forEach(x => {
      x.placements.forEach(item => {
        const releasedItem = {
          ...x, ...item, dateReleased: '',
          parentItemId: '', noDecoration: false, wearer: '',
          receivingLocation: '', releaseSequence: 0
        } as ReleasedCsgRequestItemModel;
        releasedItem.dateReleased = moment().format();
        releasedItem.parentItemId = { ...releasedItem }.id;
        releasedItem.id = uuid.v4();
        releaseThese.push(releasedItem);
      });
    });
    this.csgService.releaseCsgRequest(this.model.id, releaseThese, this.selectedLocation).subscribe(response => {
      this.notificationService.showPopup(response.message, 'success', 'Notification');
    }, err => {
      this.notificationService.showPopup('Error releasing CSG', 'danger', 'Release not successful');
    });
  }

  getFormattedLineItemName(sku: string, placementName: string): string {
    if (sku === 'TAGGING' && placementName === 'Tag') {
      return 'Apply Tag';
    } else if (sku === 'PRINTTAG' && placementName === 'Tag') {
      return 'Print Tag';
    } else if (sku === 'PRINTLABEL' && placementName === 'Packaging') {
      return 'Print Packaging Label';
    } else if (sku === 'POLYBAG' && placementName === 'Packaging') {
      return 'WE Supplied Bag';
    } else if (sku === 'BAGGING' && placementName === 'Packaging') {
      return 'Bag Garments';
    } else {
      return `${sku} / ${placementName}`;
    }
  }

  openColorMatchModal(event: Event, catalogSku: string): void {
    this.selectedDesignDetails = this.designDetails.filter(c => c.designId === catalogSku && c.type === 'ThreadColor');
    if (this.model.files) {
      this.selectedOrderFile = this.model.files.find(f => f.designId === catalogSku && f.fileType === 'StitchFile');
    }
    const releasedItems = this.model.releasedItems.filter(c => c.catalogSku === catalogSku);
    this.colorMatchReadOnly = releasedItems && releasedItems.length > 0;
    this.modalService.open('color-match-modal');
  }

  showError(message: string) {

  }

  canOpenColorMatchModal(sku: string) {
    if (!sku || sku === '') {
      return false;
    }
    const hasThreads = this.designDetails.findIndex(c => c.designId === sku && c.type === 'ThreadColor') > -1;
    // const releasedItems = this.model.releasedItems.filter(c => c.catalogSku === sku);
    // return (hasThreads && (releasedItems && releasedItems.length < 1));
    return hasThreads;
  }

  designDetailsChangedEvent(event: ColorMatchResult) {
    if (event.success) {
      this.loadCsgRequest(event.csgModel);
      this.designDetails = [];
      this.getDesignDetails(event.csgModel);
    }
  }

  ensureBoxesFullNum(numberOfBoxes: number): void {
    this.numberOfBoxes = Math.floor(numberOfBoxes);
    if (this.numberOfBoxes < 1) {
      this.numberOfBoxes = 1;
    }
  }

  toggleHasColorMatchFee(id: string) {
    this.csgService.toggleIsThreadColorMatchFee(id).subscribe(result => {

    });
  }

  showAutoReceived(item: CustomerSuppliedModelItem): boolean {
    if (this.model.autoReceiveTagsAndBags && item.item && item.item !== 'CustomerSuppliedDecoration') {
      return true;
    }

    return false;
  }

  updateCancelByDate() {
    const nowDate = new Date();
    if (this.model.cancelByDate > nowDate && this.model.cancelByDate < new Date(nowDate.setDate(nowDate.getDate() + 90))) {
      this.csgService.updateCancelByDate(this.csgRequestId, this.model.cancelByDate).subscribe(
        (res) => {
          this.notificationService.showPopup('Cancel By Date Successfully Updated', 'success', 'Cancel By Date');
        });
    } else {
      this.notificationService.showPopup(
        'Please Select a Valid Future Date No More than 90 Days From Today',
        'danger',
        'Invalid Cancel By Date'
      );
    }
  }

  getCancelDate(): Date {
    if (!this.model.cancelByDate) {
      return new Date(Date.now());
    }
    return new Date(this.model.cancelByDate);
  }

  hasThreadProblem(lineNumber: string) {
    // tslint:disable-next-line:radix
    const garmentGroupIndex = this.model.rawOrderModel.garmentGroups.findIndex(c => c.groupingId === parseInt(lineNumber));
    const garmentGroup = this.model.rawOrderModel.garmentGroups[garmentGroupIndex];
    if (garmentGroup) {
      return garmentGroup.hasInvalidThreadData;
    } else {
      return false;
    }
  }

  navigateToOtherOrder(id: string): void {
    this.router.navigate(['csgrequests', id], { relativeTo: this.route.parent });

    this.csgService.getCsgRequest(id, true).subscribe(r => {
      this.loadCsgRequest(r);
      this.getDesignDetails(r);
    });

    this.csgService.getRequestsWithSameDesignIds(id).subscribe(otherRequests => {
      this.otherRequestsWithSameDesigns = otherRequests.filter(c => c.id !== id);
      this.otherRequestsLoading = false;
    });
  }
}
