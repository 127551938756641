<div *ngIf="model">
  <div class="row">
    <div class="col">
      <a [routerLink]="['..']">Back to list</a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <div class="card" style="min-width: 25rem;">
        <div class="card-body">
          <h5 class="card-title">CSG # {{ model.csgNumber }}</h5>
          <div *ngIf="salesOrders.length > 0" class="row">
            <div class="col-sm-4 data-label">
              Sales Order
            </div>
            <div class="col-auto" *ngFor="let order of salesOrders">
              <a [routerLink]="['/orders',order.id]">{{order.id}}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Stage
            </div>
            <div class="col-auto">
              {{ model.status }}
              <span class="ml-1" *ngIf="hasIssues()">
                <button class="btn btn-sm btn-primary" (click)="reenterOrder()">Retry</button>
              </span>
              <span class="ml-1" *ngIf="hasThreadUpdatedPleaseReimport()">
                <button class="btn btn-sm btn-primary" (click)="reprocessUpdatedDesigns()">Reenter</button>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Created
            </div>
            <div class="col-auto">
              {{ longDateWithSeconds(model.createDate, true) }}
            </div>
          </div>
          <div class="row" *ngIf="model.rawOrderModel && model.rawOrderModel.impersonator != null">
            <div class="col-sm-4 data-label">
              Impersonated By
            </div>
            <div class="col-auto">
              {{ model.rawOrderModel.impersonator }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Customer
            </div>
            <div class="col-auto">
              <a [routerLink]="['/customers', model.customerId]"> {{ model.customerName }} - {{ model.customerId }}</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Customer Comments
            </div>
            <div class="col-auto">
              {{ model.customerNotes }}
            </div>
          </div>
          <div class="row" *ngIf="model && model.customerId">
            <div class="col-sm-4 data-label">
              <a routerLink="/customers/{{model.customerId}}/notifications/{{model.csgNumber}}"
                routerLinkActive="active">Notifications</a>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Number Of Boxes
            </div>
            <div class="col-auto" style="width:85px;">
              <input class="form-control" type="number" [(ngModel)]="numberOfBoxes"
                (change)="ensureBoxesFullNum(numberOfBoxes)">
            </div>
            <span (click)="saveBoxesAdd()"><i class="fa fa-2x fa-plus-circle text-success"></i></span>
            <span (click)="saveBoxesMinus()"><i class="fa fa-2x fa-minus-circle text-danger"></i></span>
            <span><button class="btn btn-sm btn-warning ml-1" (click)="saveBoxes()">Save Boxes</button></span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm">
      <div class="card" style="min-width: 25rem;">
        <div class="card-body">
          <h5 class="card-title">Details</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Client Ref. #
            </div>
            <div class="col-auto">
              {{ model.clientReferenceNumber }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Contact/Email
            </div>
            <div class="col-auto">
              {{ model.shipFirstName }}
              <a *ngIf="model.emailAddress && model.emailAddress !== ''"
                [attr.href]="'mailto:' + model.emailAddress">{{model.emailAddress}}</a>
            </div>
          </div>
          <div class="row" *ngIf="model.phoneNumber && model.phoneNumber !== ''">
            <div class="col-sm-4 data-label">
              Phone
            </div>
            <div class="col-auto">
              <a *ngIf="model.phoneNumber && model.phoneNumber !== ''"
                [attr.href]="'tel:' + model.phoneNumber">{{model.phoneNumber}}</a>
            </div>
          </div>
          <div class="row" *ngIf="model.poNumber && model.poNumber !== ''">
            <div class="col-sm-4 data-label">
              PO Number
            </div>
            <div class="col-auto">
              {{ model.poNumber }}
            </div>
          </div>
          <!-- <div class="row" *ngIf="model.rawOrderModel.IdTapeNum && model.rawOrderModel.IdTapeNum !== ''">
            <div class="col-sm-4 data-label">
              ID Tape Number
            </div>
            <div class="col-auto">
              {{ model.rawOrderModel.IdTapeNum }}
            </div>
          </div> -->
          <div class="row" *ngIf="model.rawOrderModel.vendorName && model.rawOrderModel.vendorName !== ''">
            <div class="col-sm-4 data-label">
              Vendor Name
            </div>
            <div class="col-auto">
              {{ model.vendorName }}
            </div>
          </div>
          <div class="row" *ngIf="model.rawOrderModel.vendorPO && model.rawOrderModel.vendorPO !== ''">
            <div class="col-sm-4 data-label">
              Vendor PO
            </div>
            <div class="col-auto">
              {{ model.rawOrderModel.vendorPO }}
            </div>
          </div>
          <div class="row"
            *ngIf="model.rawOrderModel.outsideVendorName && model.rawOrderModel.outsideVendorName !== ''">
            <div class="col-sm-4 data-label">
              Outside Vendor PO
            </div>
            <div class="col-auto">
              {{ model.rawOrderModel.outsideVendorName }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Tracking #
            </div>
            <div class="col-auto">
              {{ model.shipTrackingNumbers }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Warehouse Code
            </div>
            <div class="col-sm-4">
              <select class="form-control" style="display: inline-block" [(ngModel)]="selectedLocation"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let location of locationModel"
                  [value]="location.locationCode2Dig + ' ' + location.locationCode">
                  {{location.locationCode}}</option>
              </select>
            </div>
            <div class="col-sm-4">
              <button class="btn btn-sm btn-warning mr-1 ml-1" (click)="updateLocation()">Change Location</button>
            </div>

          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              External Order #
            </div>
            <div class="col-auto">
              {{ model.externalOrderNumber }}
            </div>
          </div>
          <div class="row" *ngIf="model.rawOrderModel?.garmentGroups[0]?.requestedShipmentDate">
            <div class="col-sm-4 data-label">
              Requested Shipment Date
            </div>
            <div class="col-auto">
              {{ shortDate(model.rawOrderModel?.garmentGroups[0]?.requestedShipmentDate, true) }}
            </div>
          </div>
          <div class="row" *ngIf="model.orderReference1 && model.orderReference1 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 1
            </div>
            <div class="col-auto">
              {{ model.orderReference1 }}
            </div>
          </div>
          <div class="row" *ngIf="model.orderReference2 && model.orderReference2 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 2
            </div>
            <div class="col-auto">
              {{ model.orderReference2 }}
            </div>
          </div>
          <div class="row" *ngIf="model.orderReference3 && model.orderReference3 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 3
            </div>
            <div class="col-auto">
              {{ model.orderReference3 }}
            </div>
          </div>
          <div class="row" *ngIf="model.orderReference4 && model.orderReference4 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 4
            </div>
            <div class="col-auto">
              {{ model.orderReference4 }}
            </div>
          </div>
          <div class="row" *ngIf="model.orderReference5 && model.orderReference5 !== ''">
            <div class="col-sm-4 data-label">
              Order Reference 5
            </div>
            <div class="col-auto">
              {{ model.orderReference5 }}
            </div>
          </div>
          <div class="row" *ngIf="model.rawOrderModel && model.rawOrderModel.hasManualColorMatchFee">
            <div class="col-sm-4 data-label">
              Color Match Fee
            </div>
            <div class="col-auto">
              <input id="toggleHasColorMatchFee" type="checkbox"
                [(ngModel)]="model.rawOrderModel.hasManualColorMatchFee"
                (ngModelChange)="toggleHasColorMatchFee(model.id)" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Cancel By Date
            </div>
            <div class="col-sm-4">
              <input type="text" placeholder="" class="col-sm-8 form-group datebtn btn-sm" [bsConfig]="bsConfig"
                [(ngModel)]="model.cancelByDate" value="{{ model.cancelByDate | date: 'MM/dd/yyyy'}}" bsDatepicker
                (ngModelChange)="updateCancelByDate()">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-1 mb-2">
    <div class="col-sm">
      <button *ngIf="canCancelSalesOrder()" class="btn btn-danger mr-1" style="float:right"
        (click)="cancelRequest()">Cancel CSG Request</button>
      <button class="btn btn-sm btn-warning mr-1" id="shortLabelButton"
        [ngClass]="{'disabled': loading == true || (!salesOrderDetailModel && model.status != 'Unexpected Shipment')}"
        (click)="viewCsgLabel()">Print CSG Label</button>
      <button class="btn btn-sm btn-warning mr-1" id="detailedLabelButton"
        [ngClass]="{'disabled': loading == true || (!salesOrderDetailModel && model.status != 'Unexpected Shipment')}"
        (click)="downloadDetailedLabel()">Download CSG Form</button>
      <div *ngIf="loading" class="progress" style="width:75px">
        <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
          aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
      </div>
    </div>
  </div>
  <div *ngIf="model && model.files && model.files.length" class="row">
    <div class="col-lg">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Order Files</h5>
          <div class="card-text">
            <app-order-files [files]="this.model.files"></app-order-files>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="model && model.rawOrderModel && model.rawOrderModel.garmentShipFrom" class="col-sm">
      <div class="row">
        <div class="col-lg">
          <div class="card" style="min-width: 25rem;">
            <div class="card-body">
              <h5 class="card-title" i18n>Shipped From</h5>
              <div class="card-text">
                <app-display-field
                  [model]="{ label: 'Contact', data: model.rawOrderModel.garmentShipFrom.address.contactName }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Company', data: model.rawOrderModel.garmentShipFrom.address.companyName }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Address 1', data: model.rawOrderModel.garmentShipFrom.address.line1 }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Address 2', data: model.rawOrderModel.garmentShipFrom.address.line2 }">
                </app-display-field>
                <app-display-field [model]="{ label: 'City', data: model.rawOrderModel.garmentShipFrom.address.city }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'State', data: model.rawOrderModel.garmentShipFrom.address.state }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Zip', data: model.rawOrderModel.garmentShipFrom.address.postalCode }">
                </app-display-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="model && model.rawOrderModel && model.rawOrderModel.shipToAddresses && model.rawOrderModel.shipToAddresses.length > 0"
      class="col-sm">

      <div class="row">
        <div class="col-lg">
          <div class="card" style="min-width: 25rem;">
            <div class="card-body">
              <h5 class="card-title" i18n>Ship To</h5>
              <div class="card-text">
                <div *ngFor="let address of model.rawOrderModel.shipToAddresses">
                  <app-display-field [model]="{ label: 'Contact', data: address.address.contactName }">
                  </app-display-field>
                  <app-display-field [model]="{ label: 'Company', data: address.address.companyName }">
                  </app-display-field>
                  <app-display-field [model]="{ label: 'Address 1', data: address.address.line1 }"></app-display-field>
                  <app-display-field [model]="{ label: 'Address 2', data: address.address.line2 }"></app-display-field>
                  <app-display-field [model]="{ label: 'City', data: address.address.city }"></app-display-field>
                  <app-display-field [model]="{ label: 'State', data: address.address.state }"></app-display-field>
                  <app-display-field [model]="{ label: 'Zip', data: address.address.postalCode }"></app-display-field>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="model && model.rawOrderModel && model.rawOrderModel.garmentShipTo?.address && model.rawOrderModel.garmentShipTo.address.line1"
      class="col-sm">

      <div class="row">
        <div class="col-lg">
          <div class="card" style="min-width: 27rem;">
            <div class="card-body">
              <h5 class="card-title" i18n>Ship To</h5>
              <div class="card-text">
                <app-display-field
                  [model]="{ label: 'Contact', data: model.rawOrderModel.garmentShipTo.address.contactName }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Company', data: model.rawOrderModel.garmentShipTo.address.companyName }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Address 1', data: model.rawOrderModel.garmentShipTo.address.line1 }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Address 2', data: model.rawOrderModel.garmentShipTo.address.line2 }">
                </app-display-field>
                <app-display-field [model]="{ label: 'City', data: model.rawOrderModel.garmentShipTo.address.city }">
                </app-display-field>
                <app-display-field [model]="{ label: 'State', data: model.rawOrderModel.garmentShipTo.address.state }">
                </app-display-field>
                <app-display-field
                  [model]="{ label: 'Zip', data: model.rawOrderModel.garmentShipTo.address.postalCode }">
                </app-display-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isLegacyCsg() === false">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">

          <div class="row title-row">
            <div class="col-sm-11 padding-0">
              <p class="action-header" i18n>Customer Supplied Items</p>
            </div>
            <!-- <h5 class="card-title">Customer Supplied Items</h5> -->
            <div *ngIf="!hideCustomerSupplied" class="col-sm-1 expand-collapse"
              (click)="hideCustomerSupplied = !hideCustomerSupplied">
              <div class="fa fa-2x fa-minus floated-right expand-collapse vertical-center"></div>
            </div>
            <div *ngIf="hideCustomerSupplied" class="col-sm-1 expand-collapse"
              (click)="hideCustomerSupplied = !hideCustomerSupplied">
              <div class="fa fa-2x fa-plus floated-right expand-collapse vertical-center"></div>
            </div>
          </div>
          <form name="cust_items" id="cust_items" (ngSubmit)="receiveCustomerSuppliedItem($event)">
          </form>
          <table class="table table-striped" *ngIf="!hideCustomerSupplied">
            <thead>
              <tr>
                <th>
                  Item
                </th>
                <th>
                  Description
                </th>
                <th>
                  Garment Group
                </th>
                <th>
                  Qty Expected
                </th>
                <th>
                  Received
                </th>
                <th>
                  Location
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr [ngClass]="{'alert': item.isInvalid, 'alert-danger': item.isInvalid}"
                *ngFor="let item of model.customerSuppliedItems">

                <td>
                  {{item.item}}
                </td>
                <td>
                  {{item.description}}
                </td>
                <td>
                  {{item.garmentGroup }}
                </td>
                <td>
                  {{item.qtyExpected}}
                </td>
                <td style="width: 75px">
                  <input *ngIf="showAutoReceived(item)" form="cust_items" class="form-control" style="width: 75px;"
                    min="0" type="number" required name="qtyReceived" value={{item.qtyExpected}} />

                  <input *ngIf="!showAutoReceived(item)" form="cust_items" class="form-control" style="width: 75px;"
                    min="0" type="number" required name="qtyReceived" [(ngModel)]="item.quantityReceived" />
                </td>
                <td>
                  <input form="cust_items" class="form-control" style="width: 150px;" type="text" required
                    name="location" [(ngModel)]="item.receivingLocation" />
                </td>
                <td>
                  <div style="text-align: right;">
                    <button form="cust_items" (click)="receivingItem = item" type="submit" id="receive_cust"
                      *ngIf="item.quantityReceived !== undefined && item.quantityReceived !== NaN"
                      class="btn btn-sm btn-primary mr-1">Receive</button>
                    <button form="cust_items" (click)="receivingItem = item" type="submit" id="receive_cust_all"
                      class="btn btn-sm btn-secondary mr-1">Receive All</button>
                    <div style="display: inline-block"
                      [ngClass]="{'alert': true, 'alert-danger': item.saveError, 'alert-success': item.saveSuccess }">{{
                      item.savedStatus }}</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <!-- <form name="csgForm" id="csgForm"> -->
          <h5 class="card-title">Items</h5>
          <div class="alert alert-info" *ngIf="!loading && (!model.csgRequestItems || allReleased())">
            All garments have been {{ isLegacyCsg() ? 'released' : 'received' }} from this order
          </div>
          <table class="table wei-table-striped" *ngIf="model.csgRequestItems && model.csgRequestItems.length > 0">
            <thead>
              <tr>
                <th>
                  Grouping Id
                </th>
                <th>
                  Qty Received<br />
                  /<br />
                  Damaged
                </th>
                <th>
                  Total <br />
                  Qty Ordered
                </th>
                <th>
                  Size
                </th>
                <th>
                  Color/Brand
                </th>
                <th>
                  Description
                </th>
                <th>
                  Insulated?
                </th>
                <th>
                  IsSorting?
                </th>
                <th>
                  ID Tape Number
                </th>
                <th *ngIf="isLegacyCsg()" colspan="2">
                  Sku/Placement
                </th>
                <th *ngIf="isLegacyCsg() == false" colspan="2">
                  Operations
                </th>
                <th>
                  Image
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                [ngClass]="{'alert': item.isInvalid, 'alert-danger': item.isInvalid, 'orange': hasThreadProblem(item.lineNumber) }"
                *ngFor="let item of model.csgRequestItemsModel">
                <td>
                  {{item.lineNumber}}
                </td>
                <td>
                  <span *ngIf="!isReceiving">
                    {{ item.quantityReceived }} /
                    <span>{{ item.quantityDamaged || 0 }}</span>
                  </span>

                  <div style="width: 75px" *ngIf="isReceiving">
                    <input class="form-control" type="number" min="0" placeholder="Qty"
                      [(ngModel)]="item.quantityReceived">
                  </div>
                  <div style="width: 75px" *ngIf="isReceiving">
                    <input class="form-control" type="number" min="0" placeholder="Damaged"
                      [(ngModel)]="item.quantityDamaged">
                  </div>
                </td>
                <td>
                  <span>{{ item.quantity }}</span>
                </td>
                <td>
                  <span *ngIf="!isReceiving">{{ item.size }}</span>
                  <div style="width: 50px" *ngIf="isReceiving">
                    <input class="form-control" type="text" placeholder="Size" [(ngModel)]="item.size">
                  </div>
                </td>
                <td>
                  <span *ngIf="!isReceiving">{{ item.color }}/{{ item.brand }}</span>
                  <div style="width: 100px" *ngIf="isReceiving">
                    <input class="form-control" type="text" placeholder="Color" [(ngModel)]="item.color">
                  </div>
                  <div style="width: 150px" *ngIf="isReceiving">
                    <input class="form-control" type="text" placeholder="Brand" [(ngModel)]="item.brand">
                  </div>
                </td>
                <td>
                  <span *ngIf="!isReceiving">{{ item.description }}</span>
                  <div style="width: 150px" *ngIf="isReceiving">
                    <input class="form-control" type="text" [(ngModel)]="item.description">
                  </div>
                </td>
                <!-- <td>
                    <span *ngIf="!isReceiving">{{ item.stripeDesignCode }}</span>
                    <div style="width: 150px" *ngIf="isReceiving">
                      <select class="form-control" id="stripe-{{item.id}}"
                            required (change)="setStripeName(item)"
                            [(ngModel)]="item.stripeDesignCode" name="stripe-{{item.id}}">
                        <option [value]="Unexpected">Unexpected Shipment</option>
                        <option *ngFor="let stripe of stripeDesigns" [value]="stripe.code">{{stripe.name}}</option>
                      </select>
                    </div>
                  </td> -->
                <td>
                  <span *ngIf="!isReceiving">{{ item.isInsulatedGarment ? 'Yes' : 'No' }}</span>
                  <div style="width: 50px" *ngIf="isReceiving">
                    <input class="form-control" type="checkbox" [(ngModel)]="item.isInsulatedGarment">
                  </div>
                </td>
                <td>
                  <span *ngIf="!isReceiving">{{ item.isSorting ? 'Yes' : 'No' }}</span>
                  <div style="width: 50px" *ngIf="isReceiving">
                    <input class="form-control" type="checkbox" [(ngModel)]="item.isSorting">
                  </div>
                </td>
                <td>
                  <span>{{ item.IdTapeNum }}</span>
                </td>
                <td colspan="2">
                  <div *ngFor="let placement of item.placements">
                    <!-- <span class="linkitem" *ngIf="!isReceiving && canOpenColorMatchModal(placement.catalogSku)" [attr.href]="" (click)="openColorMatchModal($event, placement.catalogSku)">{{getFormattedLineItemName(placement.catalogSku, placement.garmentPlacementName)}}</span> -->
                    <a *ngIf="!isReceiving && renderedDesignUrl(placement, item.lineNumber)"
                      [attr.href]="renderedDesignUrl(placement, item.lineNumber)">{{getFormattedLineItemName(placement.catalogSku,
                      placement.garmentPlacementName)}}</a>
                    <span
                      *ngIf="!isReceiving && !renderedDesignUrl(placement, item.lineNumber)">{{getFormattedLineItemName(placement.catalogSku,
                      placement.garmentPlacementName)}}</span>
                    <span class="thread-spool" (click)="openColorMatchModal($event, placement.catalogSku)"
                      *ngIf="!isReceiving && canOpenColorMatchModal(placement.catalogSku)">&nbsp;&nbsp;<img width="20"
                        height="25"
                        src="https://img.icons8.com/external-justicon-lineal-justicon/64/000000/external-thread-sewing-justicon-lineal-justicon.png" /></span>

                    <!-- <a *ngIf="!isReceiving && renderedDesignUrl(placement, item.lineNumber)" [attr.href]="renderedDesignUrl(placement, item.lineNumber)">{{getFormattedLineItemName(placement.catalogSku, placement.garmentPlacementName)}}</a> -->
                    <!-- <span *ngIf="!isReceiving && !renderedDesignUrl(placement.catalogSku)">{{getFormattedLineItemName(placement.catalogSku, placement.garmentPlacementName)}}</span> -->
                    <!-- <span *ngIf="!isReceiving && canOpenColorMatchModal(placement.catalogSku)">&nbsp;&nbsp;<img width="20" height="25" src="https://img.icons8.com/external-justicon-lineal-justicon/64/000000/external-thread-sewing-justicon-lineal-justicon.png"/></span> -->

                    <div *ngIf="placement.isNameDropper" class="ml-2">
                      <div *ngIf="placement.nameLine1">Name Line 1: {{placement.nameLine1}} </div>
                      <div *ngIf="placement.nameLine2">Name Line 2: {{placement.nameLine2}} </div>
                      <div *ngIf="placement.nameLine3">Name Line 3: {{placement.nameLine3}} </div>
                      <div *ngIf="placement.nameComment">Comment: {{placement.nameComment}} </div>
                    </div>
                    <div style="width: 350px" *ngIf="isReceiving">
                      <div *ngIf="item.isAddedItem">
                        No Decoration Garment
                      </div>
                      <div *ngIf="!item.isAddedItem">
                        <select class="form-control mr-1" id="catalogSku-{{placement.id}}" required
                          style="width: 105px; display: inline-block !important;" [(ngModel)]="placement.catalogSku"
                          name="catalogSku-{{placement.id}}">

                          <option [value]="305300">Unexpected Shipment</option>
                          <option *ngFor="let sku of orderSkus" [value]="sku">{{sku}}</option>
                        </select>
                        <select class="form-control" id="placement-{{item.id}}" required
                          style="width: 235px; display: inline-block !important;"
                          [(ngModel)]="placement.garmentPlacementName" name="placement-{{item.id}}">

                          <option [value]="placementdhere">Unexpected Shipment</option>
                          <option [value]="null">No Placement</option>
                          <option *ngFor="let p of placements" [value]="p.name">{{ p.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div *ngFor="let placement of item.placements" style="width: 100px; ">
                    <a *ngIf="renderedDesignUrl(placement, item.lineNumber)"
                      [attr.href]="renderedDesignUrl(placement, item.lineNumber)" target="_blank">
                      <img [attr.src]="renderedDesignUrl(placement, item.lineNumber)" height="50" width="100"
                        alt="Design Image url not available" />
                    </a>

                  </div>
                  <div>

                    <a *ngIf="item.designImageUrl" href="{{item.designImageUrl}}" target="_blank">
                      <img src="{{item.designImageUrl}}" height="50" width="100" alt="Design Image url not available" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">

          </div>
          <div class="row mt-1" *ngIf="model.csgRequestItems && !allReleased()">
            <div *ngIf="!isReceiving" class="col-sm">
              <button class="btn btn-primary mr-1" (click)="receive()" *ngIf="!receivePending"
                [disabled]="HasUnreleasableException() || disableRelease">Receive/Release Garments</button>
              <div *ngIf="receivePending" class="progress" style="width:75px">
                <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
              </div>
            </div>
            <div class="col-sm" *ngIf="isReceiving">
              <button class="btn btn-secondary" (click)="addGarment()" [disabled]="hasPendingDesignRequest()"><span
                  class="fa fa-plus"></span> Add Garment</button>
            </div>
            <div class="col-sm" *ngIf="isReceiving">
              <button class="btn btn-success mr-1" (click)="release()"
                [disabled]="disableRelease || hasPendingDesignRequest()">Receive & Release</button>
            </div>

           
            <div class="col-sm" *ngIf="isReceiving && isLegacyCsg() === false">
              <form name="receiveandhold" id="receiveandhold" (ngSubmit)="receiveAndHold()">
                <div class="row mb-2">
                  <button class="btn btn-info mr-1" type="submit">Receive & Hold</button>
                  <input *ngIf="isReceiving" placeholder="Enter Location" required class="form-control"
                    style="width: 150px; display: inline-block;" type="text" name="location"
                    [(ngModel)]="model.receivingLocation" />
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <p class="text-centered">Email Customer</p>
                  </div>
                  <div class="col-md-2"><input *ngIf="isReceiving" class="smaller-checkbox" type="checkbox"
                      name="shouldSendEmail" [(ngModel)]="shouldSendReceivingEmail" /></div>
                </div>
              </form>
            </div>
            <div class="col-sm" *ngIf="isReceiving">
              <div class="float-right">
                <button *ngIf="isReceiving" class="btn btn-danger mr-2" (click)="cancelReceive()">Cancel</button>
              </div>
            </div>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isLegacyCsg() === false">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Receive/Release History</h5>
          <div class="alert alert-info" *ngIf="!model.receivedItems || model.receivedItems.length === 0">
            No garments have been received from this request
          </div>
          <table *ngIf="model.receivedItems && model.receivedItems.length > 0" class="table">
            <thead>
              <tr>
                <th>
                  Grouping Id
                </th>
                <th>
                  Quantity
                </th>
                <th>
                  Size
                </th>
                <th>
                  Color/Brand
                </th>
                <th>
                  Description
                </th>
                <th>
                  Insulated?
                </th>
                <th>IsSorting?</th>
                <th>
                  Location
                </th>
                <th>
                  Date/Time
                </th>
                <th>
                  Released
                </th>           
                <th>
                  Released By
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of model.receivedItems">
                <td>
                  {{ item.lineNumber }}
                </td>
                <td>
                  {{ item.quantityReceived }}
                </td>
                <td>
                  {{ item.size }}
                </td>
                <td>
                  {{ item.color }}/{{ item.brand }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  {{ item.isInsulatedGarment ? 'Yes' : 'No' }}
                </td>
                <td>
                  {{ item.isSorting ? 'Yes' : 'No' }}
                </td>
                <td>
                  {{ item.receivingLocation }}
                </td>
                <td>
                  {{ longDateWithSeconds(item.dateReleased, true) }}
                </td>
                <td>
                  {{ item.isReleased ? 'Yes' : 'No' }}
                </td>
                <td>
                  {{item.releasedBy}}
                </td>
                <td>
                  <span *ngIf="!item.isReleased">
                    <input class="form-control" type="checkbox" [(ngModel)]="item.doRelease">
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1" *ngIf="model.receivedItems && anySelectedForRelease() && !hasPendingDesignRequest()">
            <button class="btn btn-success mr-1" (click)="releaseSelectedItems()"
              [ngClass]="{'disabled': HasUnreleasableException() == true }">Release</button>
          </div>
          <div class="orders" *ngIf="model.salesOrders.length">
            <h6 class="card-title">Sales Orders</h6>
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let order of model.salesOrders">
                <a [routerLink]="['/orders', order.id]">{{order.id}}</a> - {{longDateWithSeconds(order.addDate, true)}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="isLegacyCsg()">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Receive/Release History</h5>
          <div class="alert alert-info" *ngIf="!model.releasedItems || model.releasedItems.length === 0">
            No garments have been received from this request
          </div>
          <table *ngIf="model.releasedItems && model.releasedItems.length > 0" class="table">
            <thead>
              <tr>
                <th>
                  Quantity/<br />
                  Damaged
                </th>
                <th>
                  Size
                </th>
                <th>
                  Color/Brand
                </th>
                <th>
                  Description
                </th>
                <th>
                  Insulated?
                </th>
                <th>IsSorting? </th>
                <th>
                  Sku
                </th>
                <th>
                  Date/Time
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of model.releasedItems">
                <td>
                  {{ item.quantityReceived }}/{{ item.quantityDamaged || 0 }}
                </td>
                <td>
                  {{ item.size }}
                </td>
                <td>
                  {{ item.color }}/{{ item.brand }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <!-- <td>
                  {{ item.stripeDesignName }}
                </td> -->
                <td>
                  {{ item.isInsulatedGarment ? 'Yes' : 'No' }}
                </td>
                <td>{{item.isSorting ? 'Yes' : 'No'}}</td>
                <td>
                  {{ item.catalogSku }}
                </td>
                <td>
                  {{ longDateWithSeconds(item.dateReleased, true) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="orders">
            <h6 class="card-title">Sales Orders</h6>
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let order of model.salesOrders">
                <a [routerLink]="['/orders', order.id]">{{order.id}}</a> - {{longDateWithSeconds(order.addDate, true)}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Comments</h5>
          <div class="alert alert-info" *ngIf="!model.comments || model.comments.length === 0" [hidden]="commentsSaved">
            No comments have been made for this request
          </div>

          <div class="alert alert-success" *ngIf="commentsSaved">
            Your comments "{{comments}}" have been saved successfully!
          </div>
          <textarea style="width:50%;" [(ngModel)]="comments"
            [ngModelOptions]="{standalone: true}">{{model.comments}}</textarea>
          <div>
            <button class="btn btn-warning mr-1" (click)="saveComments()">Save Comments</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row"
    *ngIf="!otherRequestsLoading && otherRequestsWithSameDesigns && otherRequestsWithSameDesigns.length > 0">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{'Other Pending Requests With Same Design Ids (' + otherRequestsWithSameDesigns.length
            + ')' }}</h5>
          <table *ngIf="otherRequestsWithSameDesigns && otherRequestsWithSameDesigns.length > 0"
            class="table table-striped">
            <thead>
              <tr>
                <th>
                  Csg Number
                </th>
                <th>
                  Design Id
                </th>
                <th>
                  Customer
                </th>
                <th>
                  PO Number
                </th>
                <th>
                  Reference Number
                </th>
                <th>
                  Inbound Tracking Numbers
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let req of otherRequestsWithSameDesigns">
                <td>
                  <a [routerLink]="" routerLinkActive="active"
                    (click)="navigateToOtherOrder(req.id)">{{req.csgNumber}}</a>
                </td>
                <td>
                  <a [routerLink]="['/csgrequests']" [queryParams]="{designId: req.designId}">{{req.designId}}</a>
                </td>
                <td>
                  {{ req.companyName }}
                </td>
                <td>
                  {{ req.poNumber }}
                </td>
                <td>
                  {{ req.clientReferenceNumber }}
                </td>
                <td>
                  {{ req.shipTrackingNumbers }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<color-match-modal [designDetails]="selectedDesignDetails" [file]="selectedOrderFile" [csgId]="csgRequestId"
  [isLegacy]="isLegacyCsg()" (errorEvent)="showError($event)" [isReadOnly]="colorMatchReadOnly"
  (designDetailsChangedEvent)="designDetailsChangedEvent($event)"></color-match-modal>