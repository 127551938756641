import { DesignRequestsService } from './../../../services/design-requests.service';
import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { DesignRequestDetailItem, DeliveryDetails } from 'src/app/models';
import { longDateWithSeconds, calendarDate } from 'src/app/displayHelpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Base64 } from 'js-base64';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-episerver-dr-detail',
  templateUrl: './episerver-dr-detail.component.html',
  styleUrls: ['./../../../app.component.scss']
})
export class EpiserverDrDetailComponent implements OnInit, AfterViewInit {

  model: DesignRequestDetailItem;
  specialInstrColor: string[];
  specInstr3D: string[];
  specInstrColor: string[];
  specInstrPrintStitch: string[];
  specInstrLeather: string[];

  isReleasing = false;
  isReleased = false;
  longDateWithSeconds = longDateWithSeconds;
  calendarDate = calendarDate;
  incompleteDR = false;
  incompleteSizeInfo = false;
  incompleteColorInfo = false;
  stuff: any;
  showStatusEdit: boolean;
  emailAddresses: DeliveryDetails[];
  physicalSamples: DeliveryDetails[];
  loading = false;
  designRequestModel: any;
  environment = environment;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private designRequestService: DesignRequestsService) { }

  ngOnInit() {
    this.loading = true;
    const { requestId } = this.route.snapshot.params;
    this.designRequestService.getDesignRequest(requestId).subscribe(detail => {
      
        this.model = detail;

      if (this.model != null) {
        if (this.model.specInstr3D != null && this.model.specInstr3D != undefined && this.model.specInstr3D != undefined)
          this.specInstr3D = this.model.specInstr3D.split("~");

        if (this.model.specInstrColor != null && this.model.specInstrColor != undefined && this.model.specInstrColor != undefined)
          this.specialInstrColor = this.model.specInstrColor.split("~");

        if (this.model.specInstrPrintStitch != null && this.model.specInstrPrintStitch != undefined && this.model.specInstrPrintStitch != undefined)
          this.specInstrPrintStitch = this.model.specInstrPrintStitch.split("~");

        if (this.model.specInstrLeather != null && this.model.specInstrLeather != undefined && this.model.specInstrLeather != undefined)
          this.specInstrLeather = this.model.specInstrLeather.split("~");
      }

      if (detail != null && detail.DeliveryDetails) {
        this.emailAddresses = detail.deliveryDetails.filter(c => c.shipVia == 'email');
        this.physicalSamples = detail.deliveryDetails.filter(c => c.shipVia != 'email');
      }
      if (this.model.model?.createDesign.uploadedImage?.url && !this.model.model?.createDesign?.uploadedImageOriginal?.url) {
        this.model.model.createDesign.uploadedImageOriginal = this.model.model.createDesign.uploadedImage;
      }
      this.designRequestModel = JSON.stringify(this.model.model);

      this.isReleased = this.model.isReleased;
      this.incompleteDR = this.isIncompleteDR();
      this.loading = false;
      const ss = document.createElement('link');
      ss.rel = 'stylesheet';
      ss.href = `${environment.shopUrl}Static/colorsApp/css/site.css`;
      document.head.prepend(ss);

      if (this.model.model?.createDesign.createDesignOption !== 'EnterText') {
        setTimeout(() => {
          (window as any).initPreview();
        }, 1000);
      }

    });
  }

  ngAfterViewInit() {

  }

  numberGreaterThanZero(numString: string) {
    // tslint:disable-next-line:radix
    return numString && numString !== '' && parseInt(numString) > 0;
  }

  trimLeadingZeros(numString: string) {
    return Number(numString);
  }

  weiSizeImage(shapeSizeId: string, height: string, width: string) {
    let out = true;
    if (shapeSizeId !== '' && !this.IsNonStandardShape()) { out = false; }
    if (this.numberGreaterThanZero(height)) { out = false; }
    if (this.numberGreaterThanZero(width)) { out = false; }
    return out;
  }

  edit() {
    console.log(this.model.editUrl);
    window.open(this.model.editUrl);
  }

  release(event: Event) {
    this.isReleasing = true;
    this.designRequestService.releaseDesignRequest(this.model.id).subscribe(result => {
      this.isReleased = true;
      // this.router.navigate(['/requests']);
    }, err => {
      console.log(err);
    }, () => {
      this.isReleasing = false;
    });
  }

  updateDr(event: Event) {
    this.isReleasing = true;
    this.designRequestService.updateDesignRequest(this.model.id, { Status: this.model.status }).subscribe(result => {
      this.showStatusEdit = false;
    }, err => {
      console.log(err);
    }, () => {
      this.showStatusEdit = false;
    });
  }

  isIncompleteDR(): boolean {
    return false;
    // if (this.model.threadInkColors.length === 0) {
    //   this.incompleteColorInfo = true;
    // }
    // if (this.IsNonStandardShape() && (this.IsNullOrEmpty(this.model.height) && this.IsNullOrEmpty(this.model.width))) {
    //   this.incompleteSizeInfo = true;
    // }
    // if ((this.IsNullOrEmpty(this.model.height) &&
    //   this.IsNullOrEmpty(this.model.width)) &&
    //   this.IsNullOrEmpty(this.model.shapeSizeInfo.id)) {
    //   this.incompleteSizeInfo = true;
    // }
    // return this.incompleteColorInfo || this.incompleteSizeInfo;
  }

  IsNonStandardShape() {
    return this.NonStandardShapes.includes(this.model.drShapesName);
  }

  IsNullOrEmpty(item: string): boolean {
    return !item || item === '';
  }

  toggleSetStatus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showStatusEdit = !this.showStatusEdit;
  }

  getAddressForRow(address: DeliveryDetails) {
    return address.address + " " + (address.address2 || '') + ' ' + address.city + ' ' + address.state + ', ' + (address.PostalCode || '');
  }

  get NonStandardShapes(): string[] {
    return ['Circle', 'Square Shape / Round Corners', 'Triangle',
      'Rectangle Shape / Round Corners', 'Rectangle Shape / Square Corners', 'Oval',
      'Blunt Oval', 'Arc', 'Square Shape / Square Corners', 'Shield', 'Custom'];
  }

  getOriginalArtworkName(url : string) {
    const lastIndex = url.lastIndexOf('/');
    return url.substring(lastIndex);
  }   
}
